import React, { useState } from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function FormElementsToggleAllSecondaryPreview(props) {
  const [checked, setChecked] = useState([false, false])

  const {
    FormElementsToggleBaseSecondaryBasic,
    FormElementsToggleLgSecondaryBasic,
    FormElementsToggleBaseSecondaryDisabled,
    FormElementsToggleLgSecondaryDisabled,
    FormElementsToggleBaseSecondaryHelperText,
    FormElementsToggleLgSecondaryHelperText,
  } = props

  const handleChange = toggle => {
    const updateCheckedState = checked.map((item, index) =>
      index === toggle ? !item : item
    )
    setChecked(updateCheckedState)
  }

  return (
    <>
      <div className="flex w-full flex-col justify-center gap-24 md:flex-row ">
        <div className="flex w-full flex-col gap-12 md:w-[17rem]">
          <h3 className="font-medium text-slate-500">Base size:</h3>
          <CopyComponent
            copyToClipboardCode={FormElementsToggleBaseSecondaryBasic}
            componentName="FormElementsToggleBaseSecondaryBasic"
          >
            {/*    <!-- Component: Base sized secondary basic toggle button --> */}
            <div className="relative flex flex-wrap items-center">
              <input
                className="peer relative h-4 w-8 cursor-pointer appearance-none rounded-lg ring-2 ring-inset ring-slate-300 transition-colors after:absolute after:top-0 after:left-0 after:h-4 after:w-4 after:rounded-full after:bg-white after:ring-2 after:ring-inset after:ring-slate-500 after:transition-all checked:bg-emerald-200 checked:ring-emerald-500 checked:after:left-4 checked:after:bg-white checked:after:ring-emerald-500 hover:ring-slate-400 after:hover:ring-slate-600 checked:hover:bg-emerald-300 checked:hover:ring-emerald-600 checked:after:hover:ring-emerald-600 focus:outline-none  checked:focus:bg-emerald-400  checked:focus:ring-emerald-700 checked:after:focus:ring-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-200 disabled:after:ring-slate-300"
                type="checkbox"
                value={checked[0]}
                checked={checked[0]}
                onChange={() => handleChange(0)}
                id="id-c01s"
              />
              <label
                className="cursor-pointer pl-2 text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
                htmlFor="id-c01s"
              >
                Secondary basic
              </label>
            </div>
            {/*    <!-- End Base sized secondary basic toggle button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={FormElementsToggleBaseSecondaryDisabled}
            componentName="FormElementsToggleBaseSecondaryDisabled"
          >
            {/*    <!-- Component: Base sized secondary disabled toggle button --> */}
            <div className="relative flex flex-wrap items-center">
              <input
                className="peer relative h-4 w-8 cursor-pointer appearance-none rounded-lg ring-2 ring-inset ring-slate-300 transition-colors after:absolute after:top-0 after:left-0 after:h-4 after:w-4 after:rounded-full after:bg-white after:ring-2 after:ring-inset after:ring-slate-500 after:transition-all checked:bg-emerald-200 checked:ring-emerald-500 checked:after:left-4 checked:after:bg-white checked:after:ring-emerald-500 hover:ring-slate-400 after:hover:ring-slate-600 checked:hover:bg-emerald-300 checked:hover:ring-emerald-600 checked:after:hover:ring-emerald-600 focus:outline-none  checked:focus:bg-emerald-400  checked:focus:ring-emerald-700 checked:after:focus:ring-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-200 disabled:after:ring-slate-300"
                type="checkbox"
                value=""
                id="id-c02s"
                disabled
              />
              <label
                className="cursor-pointer pl-2 text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
                htmlFor="id-c02s"
              >
                Secondary disabled
              </label>
            </div>
            {/*    <!-- End Base sized secondary disabled toggle button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={FormElementsToggleBaseSecondaryHelperText}
            componentName="FormElementsToggleBaseSecondaryHelperText"
          >
            {/*    <!-- Component: Base sized secondary toggle button with helper text --> */}
            <div className="relative flex flex-wrap items-center">
              <input
                className="peer relative h-4 w-8 cursor-pointer appearance-none rounded-lg ring-2 ring-inset ring-slate-300 transition-colors after:absolute after:top-0 after:left-0 after:h-4 after:w-4 after:rounded-full after:bg-white after:ring-2 after:ring-inset after:ring-slate-500 after:transition-all checked:bg-emerald-200 checked:ring-emerald-500 checked:after:left-4 checked:after:bg-white checked:after:ring-emerald-500 hover:ring-slate-400 after:hover:ring-slate-600 checked:hover:bg-emerald-300 checked:hover:ring-emerald-600 checked:after:hover:ring-emerald-600 focus:outline-none  checked:focus:bg-emerald-400  checked:focus:ring-emerald-700 checked:after:focus:ring-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-200 disabled:after:ring-slate-300"
                type="checkbox"
                value={checked[1]}
                checked={checked[1]}
                onChange={() => handleChange(1)}
                id="id-c03s"
              />
              <label
                className="cursor-pointer pl-2 text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
                htmlFor="id-c03s"
              >
                Secondary with helper text
              </label>
              <small className="w-full py-2 pl-10 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                <span>Toggle can also have helper text</span>
              </small>
            </div>
            {/*    <!-- End Base sized secondary toggle button with helper text --> */}
          </CopyComponent>
        </div>

        {/*  <!-- SECONDARY LARGE SIZE --> */}

        <div className="flex w-full flex-col gap-12 md:w-[17rem]">
          <h3 className="font-medium text-slate-500">Large size:</h3>
          <CopyComponent
            copyToClipboardCode={FormElementsToggleLgSecondaryBasic}
            componentName="FormElementsToggleLgSecondaryBasic"
          >
            {/*    <!-- Component: Lg sized secondary basic toggle button --> */}
            <div className="relative flex flex-wrap items-center">
              <input
                className="peer relative h-6 w-12 cursor-pointer appearance-none rounded-xl ring-2 ring-inset ring-slate-300 transition-colors after:absolute after:top-0 after:left-0 after:h-6 after:w-6 after:rounded-full after:bg-white after:ring-2 after:ring-inset after:ring-slate-500 after:transition-all checked:bg-emerald-200 checked:ring-emerald-500 checked:after:left-6 checked:after:bg-white checked:after:ring-emerald-500 hover:ring-slate-400 after:hover:ring-slate-600 checked:hover:bg-emerald-300 checked:hover:ring-emerald-600 checked:after:hover:ring-emerald-600  checked:focus:bg-emerald-400  checked:focus:ring-emerald-700 checked:after:focus:ring-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-200 disabled:after:ring-slate-300"
                type="checkbox"
                value={checked[2]}
                checked={checked[2]}
                onChange={() => handleChange(2)}
                id="id-c04s"
              />
              <label
                className="cursor-pointer pl-2 text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
                htmlFor="id-c04s"
              >
                Secondary basic
              </label>
            </div>
            {/*    <!-- End Lg sized secondary basic toggle button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={FormElementsToggleLgSecondaryDisabled}
            componentName="FormElementsToggleLgSecondaryDisabled"
          >
            {/*    <!-- Component: Lg sized secondary disabled toggle button --> */}
            <div className="relative flex flex-wrap items-center">
              <input
                className="peer relative h-6 w-12 cursor-pointer appearance-none rounded-xl ring-2 ring-inset ring-slate-300 transition-colors after:absolute after:top-0 after:left-0 after:h-6 after:w-6 after:rounded-full after:bg-white after:ring-2 after:ring-inset after:ring-slate-500 after:transition-all checked:bg-emerald-200 checked:ring-emerald-500 checked:after:left-6 checked:after:bg-white checked:after:ring-emerald-500 hover:ring-slate-400 after:hover:ring-slate-600 checked:hover:bg-emerald-300 checked:hover:ring-emerald-600 checked:after:hover:ring-emerald-600  checked:focus:bg-emerald-400  checked:focus:ring-emerald-700 checked:after:focus:ring-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-200 disabled:after:ring-slate-300"
                type="checkbox"
                value=""
                id="id-c05s"
                disabled
              />
              <label
                className="cursor-pointer pl-2 text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
                htmlFor="id-c05s"
              >
                Secondary disabled
              </label>
            </div>
            {/*    <!-- End Lg sized secondary disabled toggle button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={FormElementsToggleLgSecondaryHelperText}
            componentName="FormElementsToggleLgSecondaryHelperText"
          >
            {/*    <!-- Component: Lg sized secondary toggle button with helper text --> */}
            <div className="relative flex flex-wrap items-center">
              <input
                className="peer relative h-6 w-12 cursor-pointer appearance-none rounded-xl ring-2 ring-inset ring-slate-300 transition-colors after:absolute after:top-0 after:left-0 after:h-6 after:w-6 after:rounded-full after:bg-white after:ring-2 after:ring-inset after:ring-slate-500 after:transition-all checked:bg-emerald-200 checked:ring-emerald-500 checked:after:left-6 checked:after:bg-white checked:after:ring-emerald-500 hover:ring-slate-400 after:hover:ring-slate-600 checked:hover:bg-emerald-300 checked:hover:ring-emerald-600 checked:after:hover:ring-emerald-600  checked:focus:bg-emerald-400  checked:focus:ring-emerald-700 checked:after:focus:ring-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-200 disabled:after:ring-slate-300"
                type="checkbox"
                value={checked[3]}
                checked={checked[3]}
                onChange={() => handleChange(3)}
                id="id-c06s"
              />
              <label
                className="cursor-pointer pl-2 text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
                htmlFor="id-c06s"
              >
                Secondary with helper text
              </label>
              <small className="w-full py-2 pl-14 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                <span>Toggle can also have helper text</span>
              </small>
            </div>
            {/*    <!-- End Lg sized secondary toggle button with helper text --> */}
          </CopyComponent>
        </div>
      </div>
    </>
  )
}
