import React, { useState } from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function FormElementsToggleAllPrimaryPreview(props) {
  const [checked, setChecked] = useState([false, false])

  const {
    FormElementsToggleBasePrimaryBasic,
    FormElementsToggleLgPrimaryBasic,
    FormElementsToggleBasePrimaryDisabled,
    FormElementsToggleLgPrimaryDisabled,
    FormElementsToggleBasePrimaryHelperText,
    FormElementsToggleLgPrimaryHelperText,
  } = props

  const handleChange = toggle => {
    const updateCheckedState = checked.map((item, index) =>
      index === toggle ? !item : item
    )
    setChecked(updateCheckedState)
  }

  return (
    <>
      <div className="flex flex-col justify-center w-full gap-24 md:flex-row ">
        <div className="flex flex-col w-full gap-12 md:w-60">
          <h3 className="font-medium text-slate-500">Base size:</h3>
          <CopyComponent
            copyToClipboardCode={FormElementsToggleBasePrimaryBasic}
            componentName="FormElementsToggleBasePrimaryBasic"
          >
            {/*    <!-- Component: Base sized primary basic toggle button --> */}
            <div className="relative flex flex-wrap items-center">
              <input
                className="relative w-8 h-4 transition-colors rounded-lg appearance-none cursor-pointer peer bg-slate-300 after:absolute after:top-0 after:left-0 after:h-4 after:w-4 after:rounded-full after:bg-slate-500 after:transition-all checked:bg-emerald-200 checked:after:left-4 checked:after:bg-emerald-500 hover:bg-slate-400 after:hover:bg-slate-600 checked:hover:bg-emerald-300 checked:after:hover:bg-emerald-600 focus:outline-none checked:focus:bg-emerald-400 checked:after:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-200 disabled:after:bg-slate-300"
                type="checkbox"
                value={checked[0]}
                checked={checked[0]}
                onChange={() => handleChange(0)}
                id="id-c01"
              />
              <label
                className="pl-2 cursor-pointer text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
                htmlFor="id-c01"
              >
                Primary basic
              </label>
            </div>
            {/*    <!-- End Base sized primary basic toggle button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={FormElementsToggleBasePrimaryDisabled}
            componentName="FormElementsToggleBasePrimaryDisabled"
          >
            {/*    <!-- Component: Base sized primary disabled toggle button --> */}
            <div className="relative flex flex-wrap items-center">
              <input
                className="relative w-8 h-4 transition-colors rounded-lg appearance-none cursor-pointer peer bg-slate-300 after:absolute after:top-0 after:left-0 after:h-4 after:w-4 after:rounded-full after:bg-slate-500 after:transition-all checked:bg-emerald-200 checked:after:left-4 checked:after:bg-emerald-500 hover:bg-slate-400 after:hover:bg-slate-600 checked:hover:bg-emerald-300 checked:after:hover:bg-emerald-600 focus:outline-none checked:focus:bg-emerald-400 checked:after:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-200 disabled:after:bg-slate-300"
                type="checkbox"
                value=""
                id="id-c02"
                disabled
              />
              <label
                className="pl-2 cursor-pointer text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
                htmlFor="id-c02"
              >
                Primary disabled
              </label>
            </div>
            {/*    <!-- End Base sized primary disabled toggle button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={FormElementsToggleBasePrimaryHelperText}
            componentName="FormElementsToggleBasePrimaryHelperText"
          >
            {/*    <!-- Component: Base sized primary toggle button with helper text --> */}
            <div className="relative flex flex-wrap items-center">
              <input
                className="relative w-8 h-4 transition-colors rounded-lg appearance-none cursor-pointer peer bg-slate-300 after:absolute after:top-0 after:left-0 after:h-4 after:w-4 after:rounded-full after:bg-slate-500 after:transition-all checked:bg-emerald-200 checked:after:left-4 checked:after:bg-emerald-500 hover:bg-slate-400 after:hover:bg-slate-600 checked:hover:bg-emerald-300 checked:after:hover:bg-emerald-600 focus:outline-none checked:focus:bg-emerald-400 checked:after:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-200 disabled:after:bg-slate-300"
                type="checkbox"
                value={checked[1]}
                checked={checked[1]}
                onChange={() => handleChange(1)}
                id="id-c03"
              />
              <label
                className="pl-2 cursor-pointer text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
                htmlFor="id-c03"
              >
                Primary with helper text
              </label>
              <small className="w-full py-2 pl-10 text-xs transition text-slate-400 peer-invalid:text-pink-500">
                <span>Toggle can also have helper text</span>
              </small>
            </div>
            {/*    <!-- End Base sized primary toggle button with helper text --> */}
          </CopyComponent>
        </div>

        {/*  <!-- PRIMARY LARGE SIZE --> */}

        <div className="flex flex-col w-full gap-12 md:w-60">
          <h3 className="font-medium text-slate-500">Large size:</h3>
          <CopyComponent
            copyToClipboardCode={FormElementsToggleLgPrimaryBasic}
            componentName="FormElementsToggleLgPrimaryBasic"
          >
            {/*    <!-- Component: Lg sized primary basic toggle button --> */}
            <div className="relative flex flex-wrap items-center">
              <input
                className="relative w-12 h-6 transition-colors appearance-none cursor-pointer peer rounded-xl bg-slate-300 after:absolute after:top-0 after:left-0 after:h-6 after:w-6 after:rounded-full after:bg-slate-500 after:transition-all checked:bg-emerald-200 checked:after:left-6 checked:after:bg-emerald-500 hover:bg-slate-400 after:hover:bg-slate-600 checked:hover:bg-emerald-300 checked:after:hover:bg-emerald-600 focus:outline-none checked:focus:bg-emerald-400 checked:after:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-200 disabled:after:bg-slate-300"
                type="checkbox"
                value={checked[2]}
                checked={checked[2]}
                onChange={() => handleChange(2)}
                id="id-c04"
              />
              <label
                className="pl-2 cursor-pointer text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
                htmlFor="id-c04"
              >
                Primary basic
              </label>
            </div>
            {/*    <!-- End Lg sized primary basic toggle button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={FormElementsToggleLgPrimaryDisabled}
            componentName="FormElementsToggleLgPrimaryDisabled"
          >
            {/*    <!-- Component: Lg sized primary disabled toggle button --> */}
            <div className="relative flex flex-wrap items-center">
              <input
                className="relative w-12 h-6 transition-colors appearance-none cursor-pointer peer rounded-xl bg-slate-300 after:absolute after:top-0 after:left-0 after:h-6 after:w-6 after:rounded-full after:bg-slate-500 after:transition-all checked:bg-emerald-200 checked:after:left-6 checked:after:bg-emerald-500 hover:bg-slate-400 after:hover:bg-slate-600 checked:hover:bg-emerald-300 checked:after:hover:bg-emerald-600 focus:outline-none checked:focus:bg-emerald-400 checked:after:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-200 disabled:after:bg-slate-300"
                type="checkbox"
                value=""
                id="id-c05"
                disabled
              />
              <label
                className="pl-2 cursor-pointer text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
                htmlFor="id-c05"
              >
                Primary disabled
              </label>
            </div>
            {/*    <!-- End Lg sized primary disabled toggle button --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={FormElementsToggleLgPrimaryHelperText}
            componentName="FormElementsToggleLgPrimaryHelperText"
          >
            {/*    <!-- Component: Lg sized primary toggle button with helper text --> */}
            <div className="relative flex flex-wrap items-center">
              <input
                className="relative w-12 h-6 transition-colors appearance-none cursor-pointer peer rounded-xl bg-slate-300 after:absolute after:top-0 after:left-0 after:h-6 after:w-6 after:rounded-full after:bg-slate-500 after:transition-all checked:bg-emerald-200 checked:after:left-6 checked:after:bg-emerald-500 hover:bg-slate-400 after:hover:bg-slate-600 checked:hover:bg-emerald-300 checked:after:hover:bg-emerald-600 focus:outline-none checked:focus:bg-emerald-400 checked:after:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-200 disabled:after:bg-slate-300"
                type="checkbox"
                value={checked[3]}
                checked={checked[3]}
                onChange={() => handleChange(3)}
                id="id-c06"
              />
              <label
                className="pl-2 cursor-pointer text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
                htmlFor="id-c06"
              >
                Primary with helper text
              </label>
              <small className="w-full py-2 text-xs transition pl-14 text-slate-400 peer-invalid:text-pink-500">
                <span>Toggle can also have helper text</span>
              </small>
            </div>
            {/*    <!-- End Lg sized primary toggle button with helper text --> */}
          </CopyComponent>
        </div>
      </div>
    </>
  )
}
