import React, { useState } from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function FormElementsToggleAllInnerIconsPreview(props) {
  const {
    FormElementsToggleLgPrimaryInnerIcon,
    FormElementsToggleLgPrimaryToggleIcon,
    FormElementsToggleLgSecondaryInnerIcon,
    FormElementsToggleLgSecondaryToggleIcon,
  } = props

  return (
    <>
      <div className="flex flex-col justify-center w-full gap-24 md:flex-row ">
        <div className="flex flex-col w-full gap-12 md:w-60">
          <h3 className="font-medium text-slate-500">Primary:</h3>
          <CopyComponent
            copyToClipboardCode={FormElementsToggleLgPrimaryInnerIcon}
            componentName="FormElementsToggleLgPrimaryInnerIcon"
          >
            {/*      <!-- Component: Lg sized primary toggle button with inner icon --> */}
            <label className="relative flex flex-wrap items-center gap-2 cursor-pointer text-slate-500">
              <input
                className="relative w-12 h-6 transition-colors appearance-none cursor-pointer peer rounded-xl bg-slate-300 after:absolute after:left-0 after:top-0 after:h-6 after:w-6 after:rounded-full after:bg-slate-500 after:transition-all checked:bg-emerald-200 checked:after:left-6 checked:after:bg-emerald-500 hover:bg-slate-400 after:hover:bg-slate-600 checked:hover:bg-emerald-300 checked:after:hover:bg-emerald-600 focus:outline-none checked:focus:bg-emerald-400 checked:after:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-200 disabled:after:bg-slate-300"
                type="checkbox"
                value=""
              />
              <div className="absolute left-6 top-0.5 flex h-5 w-5 items-center justify-center text-slate-500 opacity-100 transition-opacity peer-checked:opacity-0 peer-hover:text-slate-600 peer-focus:text-slate-700">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 7.379v-.904l6.743 6.742A3 3 0 0 1 5 12H3a1 1 0 0 1-1-1v-.586a1 1 0 0 1 .293-.707L3.56 8.44A1.5 1.5 0 0 0 4 7.38ZM6.5 12a1.5 1.5 0 0 0 3 0h-3Z"
                    clip-rule="evenodd"
                  />
                  <path d="M14 11a.997.997 0 0 1-.096.429L4.92 2.446A4 4 0 0 1 12 5v2.379c0 .398.158.779.44 1.06l1.267 1.268a1 1 0 0 1 .293.707V11ZM2.22 2.22a.75.75 0 0 1 1.06 0l10.5 10.5a.75.75 0 1 1-1.06 1.06L2.22 3.28a.75.75 0 0 1 0-1.06Z" />
                </svg>
              </div>
              <div className="absolute left-1 top-0.5 flex h-5 w-5 items-center justify-center text-emerald-500 opacity-0 transition-opacity peer-checked:opacity-100 peer-hover:text-emerald-600 peer-focus:text-emerald-700">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12 5a4 4 0 0 0-8 0v2.379a1.5 1.5 0 0 1-.44 1.06L2.294 9.707a1 1 0 0 0-.293.707V11a1 1 0 0 0 1 1h2a3 3 0 1 0 6 0h2a1 1 0 0 0 1-1v-.586a1 1 0 0 0-.293-.707L12.44 8.44A1.5 1.5 0 0 1 12 7.38V5Zm-5.5 7a1.5 1.5 0 0 0 3 0h-3Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <span>With inner icon</span>
            </label>
            {/*      <!-- End Lg sized primary toggle button with inner icon --> */}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={FormElementsToggleLgPrimaryToggleIcon}
            componentName="FormElementsToggleLgPrimaryToggleIcon"
          >
            {/*      <!-- Component: Lg sized primary toggle button with toggle icon --> */}
            <label className="relative flex flex-wrap items-center gap-2 cursor-pointer text-slate-500">
              <input
                className="relative w-12 h-6 transition-colors appearance-none cursor-pointer peer rounded-xl bg-slate-300 after:absolute after:left-0 after:top-0 after:h-6 after:w-6 after:rounded-full after:bg-slate-500 after:transition-all checked:bg-emerald-200 checked:after:left-6 checked:after:bg-emerald-500 hover:bg-slate-400 after:hover:bg-slate-600 checked:hover:bg-emerald-300 checked:after:hover:bg-emerald-600 focus:outline-none checked:focus:bg-emerald-400 checked:after:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-200 disabled:after:bg-slate-300"
                type="checkbox"
                value=""
              />
              <div className="absolute left-[1.625rem] top-0.5 flex h-5 w-5 items-center justify-center text-white opacity-0 transition-opacity peer-checked:opacity-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="w-4 h-4"
                >
                  <path d="M8 1a.75.75 0 0 1 .75.75v1.5a.75.75 0 0 1-1.5 0v-1.5A.75.75 0 0 1 8 1ZM10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0ZM12.95 4.11a.75.75 0 1 0-1.06-1.06l-1.062 1.06a.75.75 0 0 0 1.061 1.062l1.06-1.061ZM15 8a.75.75 0 0 1-.75.75h-1.5a.75.75 0 0 1 0-1.5h1.5A.75.75 0 0 1 15 8ZM11.89 12.95a.75.75 0 0 0 1.06-1.06l-1.06-1.062a.75.75 0 0 0-1.062 1.061l1.061 1.06ZM8 12a.75.75 0 0 1 .75.75v1.5a.75.75 0 0 1-1.5 0v-1.5A.75.75 0 0 1 8 12ZM5.172 11.89a.75.75 0 0 0-1.061-1.062L3.05 11.89a.75.75 0 1 0 1.06 1.06l1.06-1.06ZM4 8a.75.75 0 0 1-.75.75h-1.5a.75.75 0 0 1 0-1.5h1.5A.75.75 0 0 1 4 8ZM4.11 5.172A.75.75 0 0 0 5.173 4.11L4.11 3.05a.75.75 0 1 0-1.06 1.06l1.06 1.06Z" />
                </svg>
              </div>
              <div className="absolute left-0.5 top-0.5 flex h-5 w-5 items-center justify-center text-white opacity-100 transition-opacity peer-checked:opacity-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="w-4 h-4"
                >
                  <path d="M14.438 10.148c.19-.425-.321-.787-.748-.601A5.5 5.5 0 0 1 6.453 2.31c.186-.427-.176-.938-.6-.748a6.501 6.501 0 1 0 8.585 8.586Z" />
                </svg>
              </div>

              <span>With toggle icon</span>
            </label>
            {/*      <!-- End Lg sized primary toggle button with toggle icon --> */}
          </CopyComponent>
        </div>

        <div className="flex flex-col w-full gap-12 md:w-60">
          <h3 className="font-medium text-slate-500">Secondary:</h3>
          <CopyComponent
            copyToClipboardCode={FormElementsToggleLgSecondaryInnerIcon}
            componentName="FormElementsToggleLgSecondaryInnerIcon"
          >
            {/*<!-- Component: Lg sized secondary toggle button with inner icon --> */}
            <label className="relative flex flex-wrap items-center gap-2 cursor-pointer text-slate-500">
              <input
                className="relative w-12 h-6 transition-colors appearance-none cursor-pointer peer rounded-xl ring-2 ring-inset ring-slate-300 after:absolute after:left-0 after:top-0 after:h-6 after:w-6 after:rounded-full after:bg-white after:ring-2 after:ring-inset after:ring-slate-500 after:transition-all checked:bg-emerald-200 checked:ring-emerald-500 checked:after:left-6 checked:after:bg-white checked:after:ring-emerald-500 hover:ring-slate-400 after:hover:ring-slate-600 checked:hover:bg-emerald-300 checked:hover:ring-emerald-600 checked:after:hover:ring-emerald-600 checked:focus:bg-emerald-400 checked:focus:ring-emerald-700 checked:after:focus:ring-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-200 disabled:after:ring-slate-300"
                type="checkbox"
                value=""
              />
              <div className="absolute left-6 top-0.5 flex h-5 w-5 items-center justify-center text-slate-500 opacity-100 transition-opacity peer-checked:opacity-0 peer-hover:text-slate-600 peer-focus:text-slate-700">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 7.379v-.904l6.743 6.742A3 3 0 0 1 5 12H3a1 1 0 0 1-1-1v-.586a1 1 0 0 1 .293-.707L3.56 8.44A1.5 1.5 0 0 0 4 7.38ZM6.5 12a1.5 1.5 0 0 0 3 0h-3Z"
                    clip-rule="evenodd"
                  />
                  <path d="M14 11a.997.997 0 0 1-.096.429L4.92 2.446A4 4 0 0 1 12 5v2.379c0 .398.158.779.44 1.06l1.267 1.268a1 1 0 0 1 .293.707V11ZM2.22 2.22a.75.75 0 0 1 1.06 0l10.5 10.5a.75.75 0 1 1-1.06 1.06L2.22 3.28a.75.75 0 0 1 0-1.06Z" />
                </svg>
              </div>
              <div className="absolute left-1 top-0.5 flex h-5 w-5 items-center justify-center text-emerald-500 opacity-0 transition-opacity peer-checked:opacity-100 peer-hover:text-emerald-600 peer-focus:text-emerald-700">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12 5a4 4 0 0 0-8 0v2.379a1.5 1.5 0 0 1-.44 1.06L2.294 9.707a1 1 0 0 0-.293.707V11a1 1 0 0 0 1 1h2a3 3 0 1 0 6 0h2a1 1 0 0 0 1-1v-.586a1 1 0 0 0-.293-.707L12.44 8.44A1.5 1.5 0 0 1 12 7.38V5Zm-5.5 7a1.5 1.5 0 0 0 3 0h-3Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <span>With inner icon</span>
            </label>
            {/*<!-- End Lg sized secondary toggle button with inner icon -->*/}
          </CopyComponent>

          <CopyComponent
            copyToClipboardCode={FormElementsToggleLgSecondaryToggleIcon}
            componentName="FormElementsToggleLgSecondaryToggleIcon"
          >
            {/*<!-- Component: Lg sized secondary toggle button with toggle icon --> */}
            <label className="relative flex flex-wrap items-center gap-2 cursor-pointer text-slate-500">
              <input
                className="relative w-12 h-6 transition-colors appearance-none cursor-pointer peer rounded-xl ring-2 ring-inset ring-slate-300 after:absolute after:left-0 after:top-0 after:h-6 after:w-6 after:rounded-full after:bg-white after:ring-2 after:ring-inset after:ring-slate-500 after:transition-all checked:bg-emerald-200 checked:ring-emerald-500 checked:after:left-6 checked:after:bg-white checked:after:ring-emerald-500 hover:ring-slate-400 after:hover:ring-slate-600 checked:hover:bg-emerald-300 checked:hover:ring-emerald-600 checked:after:hover:ring-emerald-600 checked:focus:bg-emerald-400 checked:focus:ring-emerald-700 checked:after:focus:ring-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-200 disabled:after:ring-slate-300"
                type="checkbox"
                value=""
              />
              <div className="absolute left-0.5 top-0.5 flex h-5 w-5 items-center justify-center text-slate-500 opacity-100 transition-opacity peer-checked:opacity-0 peer-hover:text-slate-600 peer-focus:text-slate-700">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="w-4 h-4"
                >
                  <path d="M14.438 10.148c.19-.425-.321-.787-.748-.601A5.5 5.5 0 0 1 6.453 2.31c.186-.427-.176-.938-.6-.748a6.501 6.501 0 1 0 8.585 8.586Z" />
                </svg>
              </div>
              <div className="absolute left-[1.625rem] top-0.5 flex h-5 w-5 items-center justify-center text-emerald-500 opacity-0 transition-opacity peer-checked:opacity-100 peer-hover:text-emerald-600 peer-focus:text-emerald-700">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="w-4 h-4"
                >
                  <path d="M8 1a.75.75 0 0 1 .75.75v1.5a.75.75 0 0 1-1.5 0v-1.5A.75.75 0 0 1 8 1ZM10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0ZM12.95 4.11a.75.75 0 1 0-1.06-1.06l-1.062 1.06a.75.75 0 0 0 1.061 1.062l1.06-1.061ZM15 8a.75.75 0 0 1-.75.75h-1.5a.75.75 0 0 1 0-1.5h1.5A.75.75 0 0 1 15 8ZM11.89 12.95a.75.75 0 0 0 1.06-1.06l-1.06-1.062a.75.75 0 0 0-1.062 1.061l1.061 1.06ZM8 12a.75.75 0 0 1 .75.75v1.5a.75.75 0 0 1-1.5 0v-1.5A.75.75 0 0 1 8 12ZM5.172 11.89a.75.75 0 0 0-1.061-1.062L3.05 11.89a.75.75 0 1 0 1.06 1.06l1.06-1.06ZM4 8a.75.75 0 0 1-.75.75h-1.5a.75.75 0 0 1 0-1.5h1.5A.75.75 0 0 1 4 8ZM4.11 5.172A.75.75 0 0 0 5.173 4.11L4.11 3.05a.75.75 0 1 0-1.06 1.06l1.06 1.06Z" />
                </svg>
              </div>

              <span>With toggle icon</span>
            </label>
            {/*<!-- End Lg sized secondary toggle button with toggle icon -->*/}
          </CopyComponent>
        </div>
      </div>
    </>
  )
}
