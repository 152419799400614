import React, { useState } from "react"

// Components
import Seo from "../../../../components/seo"
import SidebarLayout from "../../../../layouts/SidebarLayout"
import Section from "../../../../components/section/Section"
import ContentNavigation from "../../../../components/scrollspy"

// Libraries / Components for Preview
import PreviewBlock from "../../../../components/preview"

import FormElementsToggleAllPrimaryBasicPreview from "../../../../library/components/form-elements/toggle/react/_preview/primary"
import FormElementsToggleAllSecondaryBasicPreview from "../../../../library/components/form-elements/toggle/react/_preview/secondary"
import FormElementsToggleAllInnerIconsPreview from "../../../../library/components/form-elements/toggle/react/_preview/inner_icons"
import FormElementsToggleAllCustomPreview from "../../../../library/components/form-elements/toggle/react/_preview/custom"

import HeroToggleComponent from "../../../../library/components/form-elements/toggle/react/_preview/heroToggleComponent"

// *** Toggle *** //
// Base - Primary
const FormElementsToggleBasePrimaryBasicJsx = require("!!raw-loader!../../../../library/components/form-elements/toggle/react/base/primary-basic.jsx")
const FormElementsToggleBasePrimaryBasicHTML = require("!!raw-loader!../../../../library/components/form-elements/toggle/html/base/primary-basic.html")

const FormElementsToggleBasePrimaryDisabledJsx = require("!!raw-loader!../../../../library/components/form-elements/toggle/react/base/primary-disabled.jsx")
const FormElementsToggleBasePrimaryDisabledHTML = require("!!raw-loader!../../../../library/components/form-elements/toggle/html/base/primary-disabled.html")

const FormElementsToggleBasePrimaryHelperTextJsx = require("!!raw-loader!../../../../library/components/form-elements/toggle/react/base/primary-helper_text.jsx")
const FormElementsToggleBasePrimaryHelperTextHTML = require("!!raw-loader!../../../../library/components/form-elements/toggle/html/base/primary-helper_text.html")

// Lg - Primary
const FormElementsToggleLgPrimaryBasicJsx = require("!!raw-loader!../../../../library/components/form-elements/toggle/react/lg/primary-basic.jsx")
const FormElementsToggleLgPrimaryBasicHTML = require("!!raw-loader!../../../../library/components/form-elements/toggle/html/lg/primary-basic.html")

const FormElementsToggleLgPrimaryDisabledJsx = require("!!raw-loader!../../../../library/components/form-elements/toggle/react/lg/primary-disabled.jsx")
const FormElementsToggleLgPrimaryDisabledHTML = require("!!raw-loader!../../../../library/components/form-elements/toggle/html/lg/primary-disabled.html")

const FormElementsToggleLgPrimaryHelperTextJsx = require("!!raw-loader!../../../../library/components/form-elements/toggle/react/lg/primary-helper_text.jsx")
const FormElementsToggleLgPrimaryHelperTextHTML = require("!!raw-loader!../../../../library/components/form-elements/toggle/html/lg/primary-helper_text.html")

// Base - Secondary
const FormElementsToggleBaseSecondaryBasicJsx = require("!!raw-loader!../../../../library/components/form-elements/toggle/react/base/secondary-basic.jsx")
const FormElementsToggleBaseSecondaryBasicHTML = require("!!raw-loader!../../../../library/components/form-elements/toggle/html/base/secondary-basic.html")

const FormElementsToggleBaseSecondaryDisabledJsx = require("!!raw-loader!../../../../library/components/form-elements/toggle/react/base/secondary-disabled.jsx")
const FormElementsToggleBaseSecondaryDisabledHTML = require("!!raw-loader!../../../../library/components/form-elements/toggle/html/base/secondary-disabled.html")

const FormElementsToggleBaseSecondaryHelperTextJsx = require("!!raw-loader!../../../../library/components/form-elements/toggle/react/base/secondary-helper_text.jsx")
const FormElementsToggleBaseSecondaryHelperTextHTML = require("!!raw-loader!../../../../library/components/form-elements/toggle/html/base/secondary-helper_text.html")

// Lg - Secondary
const FormElementsToggleLgSecondaryBasicJsx = require("!!raw-loader!../../../../library/components/form-elements/toggle/react/lg/secondary-basic.jsx")
const FormElementsToggleLgSecondaryBasicHTML = require("!!raw-loader!../../../../library/components/form-elements/toggle/html/lg/secondary-basic.html")

const FormElementsToggleLgSecondaryDisabledJsx = require("!!raw-loader!../../../../library/components/form-elements/toggle/react/lg/secondary-disabled.jsx")
const FormElementsToggleLgSecondaryDisabledHTML = require("!!raw-loader!../../../../library/components/form-elements/toggle/html/lg/secondary-disabled.html")

const FormElementsToggleLgSecondaryHelperTextJsx = require("!!raw-loader!../../../../library/components/form-elements/toggle/react/lg/secondary-helper_text.jsx")
const FormElementsToggleLgSecondaryHelperTextHTML = require("!!raw-loader!../../../../library/components/form-elements/toggle/html/lg/secondary-helper_text.html")

// Inner icons
const FormElementsToggleLgPrimaryInnerIconJsx = require("!!raw-loader!../../../../library/components/form-elements/toggle/react/lg/primary-inner_icon.jsx")
const FormElementsToggleLgPrimaryInnerIconHTML = require("!!raw-loader!../../../../library/components/form-elements/toggle/html/lg/primary-inner_icon.html")

const FormElementsToggleLgPrimaryToggleIconJsx = require("!!raw-loader!../../../../library/components/form-elements/toggle/react/lg/primary-toggle_icon.jsx")
const FormElementsToggleLgPrimaryToggleIconHTML = require("!!raw-loader!../../../../library/components/form-elements/toggle/html/lg/primary-toggle_icon.html")

const FormElementsToggleLgSecondaryInnerIconJsx = require("!!raw-loader!../../../../library/components/form-elements/toggle/react/lg/secondary-inner_icon.jsx")
const FormElementsToggleLgSecondaryInnerIconHTML = require("!!raw-loader!../../../../library/components/form-elements/toggle/html/lg/secondary-inner_icon.html")

const FormElementsToggleLgSecondaryToggleIconJsx = require("!!raw-loader!../../../../library/components/form-elements/toggle/react/lg/secondary-toggle_icon.jsx")
const FormElementsToggleLgSecondaryToggleIconHTML = require("!!raw-loader!../../../../library/components/form-elements/toggle/html/lg/secondary-toggle_icon.html")

// Day/night
const FormElementsToggleLgDayNightJsx = require("!!raw-loader!../../../../library/components/form-elements/toggle/react/lg/custom_dn.jsx")
const FormElementsToggleLgDayNightHTML = require("!!raw-loader!../../../../library/components/form-elements/toggle/html/lg/custom_dn.html")

const FormElementsToggleXlDayNightJsx = require("!!raw-loader!../../../../library/components/form-elements/toggle/react/xl/custom_dn.jsx")
const FormElementsToggleXlDayNightHTML = require("!!raw-loader!../../../../library/components/form-elements/toggle/html/xl/custom_dn.html")

export default function FormElementTogglesPage() {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "PrimaryToggles",
      title: "Primary toggles",
      active_tab: 1,
    },
    {
      component_name: "SecondaryToggles",
      title: "Secondary",
      active_tab: 1,
    },
    {
      component_name: "TogglesWithIcons",
      title: "With icons",
      active_tab: 1,
    },
    {
      component_name: "DayNightToggles",
      title: "Day/Night",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }
  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Toggles - WindUI Component Library"
        ogtitle="Tailwind CSS Toggles - WindUI Component Library"
        description="Toggle components, switch-toggle the state of a single setting on or off. They are preferred to adjust settings on mobile. Built with Tailwind CSS by WindUI."
        ogdescription="Toggle components, switch-toggle the state of a single setting on or off. They are preferred to adjust settings on mobile. Built with Tailwind CSS by WindUI."
        url="components/form-elements/toggles/"
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Toggles, Toggles, Toggle components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Toggles</h1>
          <p>
            Switches - toggle the state of a single setting on or off. They are
            the preferred way to adjust settings on mobile.
          </p>

          {/* Toggle Preview Section */}
          <section
            className="not-prose max-w-full"
            aria-multiselectable="false"
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:p-16">
              <HeroToggleComponent />
            </div>
          </section>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          {/* Primary Toggles Section*/}
          <h3 id="PrimaryToggles">Primary Toggles </h3>

          <PreviewBlock
            id="PrimaryToggles"
            HtmlComponent={FormElementsToggleBasePrimaryBasicHTML.default}
            JsxComponent={FormElementsToggleBasePrimaryBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <FormElementsToggleAllPrimaryBasicPreview
                FormElementsToggleBasePrimaryBasic={
                  activeTabs[0].active_tab === 1
                    ? FormElementsToggleBasePrimaryBasicHTML.default
                    : FormElementsToggleBasePrimaryBasicJsx.default
                }
                FormElementsToggleLgPrimaryBasic={
                  activeTabs[0].active_tab === 1
                    ? FormElementsToggleLgPrimaryBasicHTML.default
                    : FormElementsToggleLgPrimaryBasicJsx.default
                }
                FormElementsToggleBasePrimaryDisabled={
                  activeTabs[0].active_tab === 1
                    ? FormElementsToggleBasePrimaryDisabledHTML.default
                    : FormElementsToggleBasePrimaryDisabledJsx.default
                }
                FormElementsToggleLgPrimaryDisabled={
                  activeTabs[0].active_tab === 1
                    ? FormElementsToggleLgPrimaryDisabledHTML.default
                    : FormElementsToggleLgPrimaryDisabledJsx.default
                }
                FormElementsToggleBasePrimaryHelperText={
                  activeTabs[0].active_tab === 1
                    ? FormElementsToggleBasePrimaryHelperTextHTML.default
                    : FormElementsToggleBasePrimaryHelperTextJsx.default
                }
                FormElementsToggleLgPrimaryHelperText={
                  activeTabs[0].active_tab === 1
                    ? FormElementsToggleLgPrimaryHelperTextHTML.default
                    : FormElementsToggleLgPrimaryHelperTextJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Secondary Toggles*/}
          <h3 id="SecondaryToggles">Secondary Toggles </h3>

          <PreviewBlock
            id="SecondaryToggles"
            HtmlComponent={FormElementsToggleBaseSecondaryBasicHTML.default}
            JsxComponent={FormElementsToggleBaseSecondaryBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <FormElementsToggleAllSecondaryBasicPreview
                FormElementsToggleBaseSecondaryBasic={
                  activeTabs[1].active_tab === 1
                    ? FormElementsToggleBaseSecondaryBasicHTML.default
                    : FormElementsToggleBaseSecondaryBasicJsx.default
                }
                FormElementsToggleLgSecondaryBasic={
                  activeTabs[1].active_tab === 1
                    ? FormElementsToggleLgSecondaryBasicHTML.default
                    : FormElementsToggleLgSecondaryBasicJsx.default
                }
                FormElementsToggleBaseSecondaryDisabled={
                  activeTabs[1].active_tab === 1
                    ? FormElementsToggleBaseSecondaryDisabledHTML.default
                    : FormElementsToggleBaseSecondaryDisabledJsx.default
                }
                FormElementsToggleLgSecondaryDisabled={
                  activeTabs[1].active_tab === 1
                    ? FormElementsToggleLgSecondaryDisabledHTML.default
                    : FormElementsToggleLgSecondaryDisabledJsx.default
                }
                FormElementsToggleBaseSecondaryHelperText={
                  activeTabs[1].active_tab === 1
                    ? FormElementsToggleBaseSecondaryHelperTextHTML.default
                    : FormElementsToggleBaseSecondaryHelperTextJsx.default
                }
                FormElementsToggleLgSecondaryHelperText={
                  activeTabs[1].active_tab === 1
                    ? FormElementsToggleLgSecondaryHelperTextHTML.default
                    : FormElementsToggleLgSecondaryHelperTextJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Inner icons */}
          <h3 id="TogglesWithIcons">Toggles with Icons</h3>

          <PreviewBlock
            id="TogglesWithIcons"
            HtmlComponent={FormElementsToggleLgPrimaryInnerIconHTML.default}
            JsxComponent={FormElementsToggleLgPrimaryInnerIconJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <FormElementsToggleAllInnerIconsPreview
                FormElementsToggleLgPrimaryInnerIcon={
                  activeTabs[2].active_tab === 1
                    ? FormElementsToggleLgPrimaryInnerIconHTML.default
                    : FormElementsToggleLgPrimaryInnerIconJsx.default
                }
                FormElementsToggleLgPrimaryToggleIcon={
                  activeTabs[2].active_tab === 1
                    ? FormElementsToggleLgPrimaryToggleIconHTML.default
                    : FormElementsToggleLgPrimaryToggleIconJsx.default
                }
                FormElementsToggleLgSecondaryInnerIcon={
                  activeTabs[2].active_tab === 1
                    ? FormElementsToggleLgSecondaryInnerIconHTML.default
                    : FormElementsToggleLgSecondaryInnerIconJsx.default
                }
                FormElementsToggleLgSecondaryToggleIcon={
                  activeTabs[2].active_tab === 1
                    ? FormElementsToggleLgSecondaryToggleIconHTML.default
                    : FormElementsToggleLgSecondaryToggleIconJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Inner icons */}
          <h3 id="DayNightToggles">Day/Night Toggles</h3>

          <PreviewBlock
            id="DayNightToggles"
            HtmlComponent={FormElementsToggleLgDayNightHTML.default}
            JsxComponent={FormElementsToggleLgDayNightJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <FormElementsToggleAllCustomPreview
                FormElementsToggleLgDayNight={
                  activeTabs[3].active_tab === 1
                    ? FormElementsToggleLgDayNightHTML.default
                    : FormElementsToggleLgDayNightJsx.default
                }
                FormElementsToggleXlDayNight={
                  activeTabs[3].active_tab === 1
                    ? FormElementsToggleXlDayNightHTML.default
                    : FormElementsToggleXlDayNightJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>Use switches to:</p>
          <ul>
            <li>Toggle a single item on or off</li>
            <li>Immediately activate or deactivate something</li>
          </ul>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>
            Toggles are created using the same markup as the normal checkboxes:
          </p>
          <code className="code code--block">
            &lt;input className=&quot;..&quot; type=&quot;checkbox&quot;
            value=&quot;&quot; id=&quot;idname&quot; /&gt; &lt;label
            className=&quot;..&quot; for=&quot;idname&quot;&gt; Switch label
            &lt;/label&gt;
          </code>
          <p>
            And the only difference is the tailwind classes applied. They come
            in two basic styles (<strong>primary</strong> and{" "}
            <strong>secondary</strong>) and two sizes:
          </p>
          <ul>
            <li>
              <strong>Base:</strong> 1rem (16px) height and 2rem (32px) wide.
            </li>
            <li>
              <strong>Large:</strong> 1.5rem (24px) height and 3rem (48px) wide.
            </li>
          </ul>
          <p>
            They can contain a helper text with a font size of 0.75rem (12px)
          </p>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <p>
            Since toggles are built with the native{" "}
            <code>&lt;input type=&quot;checkbox&quot;&gt;</code> element no
            additional accessibility roles are required.
          </p>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}
