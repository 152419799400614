import React, { useState } from "react"

function HeroToggleComponent() {
  const [checked, setChecked] = useState([true, true])

  const handleChange = toggle => {
    const updateCheckedState = checked.map((item, index) =>
      index === toggle ? !item : item
    )
    setChecked(updateCheckedState)
  }

  return (
    <div className="flex flex-col flex-wrap items-start gap-8 md:flex-row md:items-center">
      {/* Basic */}
      <div>
        {/*<!-- lg sized --> */}
        <div className="relative flex flex-wrap items-center">
          <input
            className="relative w-12 h-6 transition-colors appearance-none cursor-pointer peer rounded-xl bg-slate-300 after:absolute after:top-0 after:left-0 after:h-6 after:w-6 after:rounded-full after:bg-slate-500 after:transition-all checked:bg-emerald-200 checked:after:left-6 checked:after:bg-emerald-500 hover:bg-slate-400 after:hover:bg-slate-600 checked:hover:bg-emerald-300 checked:after:hover:bg-emerald-600 focus:outline-none checked:focus:bg-emerald-400 checked:after:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-200 disabled:after:bg-slate-300"
            type="checkbox"
            value={checked[0]}
            checked={checked[0]}
            onChange={() => handleChange(0)}
            id="id-c04l"
          />
          <label
            className="pl-2 cursor-pointer text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
            htmlFor="id-c04l"
          >
            Primary
          </label>
        </div>
      </div>

      {/* Secondary */}
      <div>
        {/*<!-- lg sized --> */}
        <div className="relative flex flex-wrap items-center">
          <input
            className="relative w-12 h-6 transition-colors appearance-none cursor-pointer peer rounded-xl ring-2 ring-inset ring-slate-300 after:absolute after:top-0 after:left-0 after:h-6 after:w-6 after:rounded-full after:bg-white after:ring-2 after:ring-inset after:ring-slate-500 after:transition-all checked:bg-emerald-200 checked:ring-emerald-500 checked:after:left-6 checked:after:bg-white checked:after:ring-emerald-500 hover:ring-slate-400 after:hover:ring-slate-600 checked:hover:bg-emerald-300 checked:hover:ring-emerald-600 checked:after:hover:ring-emerald-600 checked:focus:bg-emerald-400 checked:focus:ring-emerald-700 checked:after:focus:ring-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-200 disabled:after:ring-slate-300"
            type="checkbox"
            value={checked[1]}
            checked={checked[1]}
            onChange={() => handleChange(1)}
            id="id-c04sl"
          />
          <label
            className="pl-2 cursor-pointer text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
            htmlFor="id-c04sl"
          >
            Secondary
          </label>
        </div>
      </div>
    </div>
  )
}

export default HeroToggleComponent
