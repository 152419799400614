import React, { useState } from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function FormElementsToggleAllCustomPreview(props) {
  const { FormElementsToggleLgDayNight, FormElementsToggleXlDayNight } = props

  return (
    <>
      <div className="flex flex-col justify-center w-full gap-24 md:flex-row ">
        <CopyComponent
          copyToClipboardCode={FormElementsToggleLgDayNight}
          componentName="FormElementsToggleLgDayNight"
          extraClasses="inline-flex"
        >
          {/*<!-- Component: Lg sized day/night toggle button --> */}
          <div>
            <input
              type="checkbox"
              className="peer absolute -left-[65rem]"
              id="dn-toggle-bs"
            />
            <label
              for="dn-toggle-bs"
              className="toggle relative inline-block h-6 w-12 cursor-pointer overflow-clip rounded-full border border-sky-300 bg-sky-300 transition-colors duration-200 peer-checked:border-sky-800 peer-checked:bg-sky-800 peer-disabled:cursor-not-allowed peer-checked:[&_.crater]:opacity-100 peer-checked:[&_.star-1]:left-2 peer-checked:[&_.star-1]:top-3 peer-checked:[&_.star-1]:h-0.5 peer-checked:[&_.star-1]:w-0.5 peer-checked:[&_.star-2]:left-4 peer-checked:[&_.star-2]:top-1.5 peer-checked:[&_.star-2]:h-1 peer-checked:[&_.star-2]:w-1 peer-checked:[&_.star-3]:left-4 peer-checked:[&_.star-3]:top-4 peer-checked:[&_.star-3]:h-0.5 peer-checked:[&_.star-3]:w-0.5 peer-checked:[&_.toggle-handler]:-left-4 peer-checked:[&_.toggle-handler]:translate-x-10 peer-checked:[&_.toggle-handler]:rotate-0 peer-checked:[&_.toggle-handler]:bg-amber-100"
            >
              <span className="toggle-handler relative left-0 top-0 z-10 inline-block h-6 w-6 -rotate-45 rounded-full bg-amber-300 shadow transition-all duration-[400ms]">
                <span className="absolute w-px h-px transition-opacity duration-200 rounded-full opacity-0 crater left-1 top-2 bg-amber-200"></span>
                <span className="crater absolute left-2.5 top-3.5 h-1 w-1 rounded-full bg-amber-200 opacity-0 transition-opacity duration-200"></span>
                <span className="crater absolute left-3 top-1 h-1.5 w-1.5 rounded-full bg-amber-200 opacity-0 transition-opacity duration-200"></span>
              </span>
              <span className="absolute w-4 h-4 transition-all duration-300 bg-white rounded-full star-1 left-3 top-4"></span>
              <span className="star-2 absolute left-6 top-3.5 h-4 w-4 rounded-full bg-white transition-all duration-300"></span>
              <span className="absolute w-5 h-5 transition-all duration-300 bg-white rounded-full star-3 left-9 top-2"></span>
              <span className="sr-only">toggle switch</span>
            </label>
          </div>
          {/*<!-- End Lg sized day/night toggle button -->*/}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={FormElementsToggleXlDayNight}
          componentName="FormElementsToggleXlDayNight"
          extraClasses="inline-flex"
        >
          {/*<!-- Component: Xl sized day/night toggle button --> */}
          <div>
            <input
              type="checkbox"
              className="peer absolute -left-[65rem]"
              id="dn-toggle-lg"
            />
            <label
              for="dn-toggle-lg"
              className="toggle relative inline-block h-12 w-24 cursor-pointer overflow-clip rounded-full bg-sky-300 transition-colors duration-200 peer-checked:bg-sky-900 peer-disabled:cursor-not-allowed peer-checked:[&_.crater]:opacity-100 peer-checked:[&_.star-1]:left-5 peer-checked:[&_.star-1]:top-8 peer-checked:[&_.star-1]:h-px peer-checked:[&_.star-1]:w-px peer-checked:[&_.star-2]:left-9 peer-checked:[&_.star-2]:top-2.5 peer-checked:[&_.star-2]:h-0.5 peer-checked:[&_.star-2]:w-0.5 peer-checked:[&_.star-3]:left-5 peer-checked:[&_.star-3]:top-5 peer-checked:[&_.star-3]:h-1 peer-checked:[&_.star-3]:w-1 peer-checked:[&_.star-4]:left-9 peer-checked:[&_.star-4]:top-7 peer-checked:[&_.star-4]:h-0.5 peer-checked:[&_.star-4]:w-0.5 peer-checked:[&_.toggle-handler]:left-2.5 peer-checked:[&_.toggle-handler]:translate-x-10 peer-checked:[&_.toggle-handler]:rotate-0 peer-checked:[&_.toggle-handler]:bg-amber-100"
            >
              <span className="toggle-handler relative left-0.5 top-0.5 z-10 inline-block h-11 w-11 -rotate-45 rounded-full bg-amber-300 shadow-md transition-all duration-[400ms]">
                <span className="absolute w-1 h-1 transition-opacity duration-200 rounded-full opacity-0 crater left-2 top-5 bg-amber-200"></span>
                <span className="crater absolute left-5 top-7 h-1.5 w-1.5 rounded-full bg-amber-200 opacity-0 transition-opacity duration-200"></span>
                <span className="crater absolute left-6 top-2.5 h-2 w-2 rounded-full bg-amber-200 opacity-0 transition-opacity duration-200"></span>
              </span>
              <span className="absolute w-12 h-12 transition-all duration-300 bg-white rounded-full star-1 left-1 top-9"></span>
              <span className="absolute w-8 h-8 transition-all duration-300 bg-white rounded-full star-2 left-7 top-9"></span>
              <span className="absolute transition-all duration-300 bg-white rounded-full star-3 left-12 top-8 h-9 w-9"></span>
              <span className="star-4 absolute left-[4.375rem] top-5 h-8 w-8 rounded-full bg-white transition-all duration-300"></span>
              <span className="sr-only">toggle switch</span>
            </label>
          </div>
          {/*<!-- End Xl sized day/night toggle button -->*/}
        </CopyComponent>
      </div>
    </>
  )
}
